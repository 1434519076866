import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel";

var Slick =
/** @class */
function () {
  function Slick($el, opts) {
    this.$el = $el.slick(opts);
  }

  Slick.prototype.slickPrev = function () {
    this.$el.slick("slickPrev");
  };

  Slick.prototype.slickNext = function () {
    this.$el.slick("slickNext");
  };

  Slick.prototype.slickPause = function () {
    this.$el.slick("slickPause");
  };

  Slick.prototype.slickPlay = function () {
    this.$el.slick("slickPlay");
  };

  Slick.prototype.slickSetOption = function (option, value, refresh) {
    this.$el.slick("slickSetOption", option, value, refresh);
  };

  Slick.prototype.slickGoTo = function (index) {
    this.$el.slick("slickGoTo", index);
  };

  Slick.prototype.slickCurrentSlide = function () {
    this.$el.slick("slickCurrentSlide");
  };

  Slick.prototype.onAfterChange = function (handler) {
    this.$el.on("afterChange", handler);
  };

  Slick.prototype.onBeforeChange = function (handler) {
    this.$el.on("beforeChange", handler);
  };

  Slick.prototype.onSlickSetPosition = function (handler) {
    this.$el.on("slickSetPosition", handler);
  };

  return Slick;
}();

export { Slick };
export function slickNext($el) {
  $el.slick("slickNext");
}