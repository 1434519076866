/* autogen in tsEmplate for icon */
export var Icon = {
  "checkbox_fill": "icon--account-checkbox-fill",
  "companyicon": "icon--account-companyicon",
  "qrcode-icon__hover": "icon--account-qrcode-icon-hover",
  "qrcode-icon": "icon--account-qrcode-icon",
  "01__active": "icon--bgmenu-01-active",
  "01__hover": "icon--bgmenu-01-hover",
  "01": "icon--bgmenu-01",
  "02__active": "icon--bgmenu-02-active",
  "02__hover": "icon--bgmenu-02-hover",
  "02": "icon--bgmenu-02",
  "03__active": "icon--bgmenu-03-active",
  "03__hover": "icon--bgmenu-03-hover",
  "03": "icon--bgmenu-03",
  "04__active": "icon--bgmenu-04-active",
  "04__hover": "icon--bgmenu-04-hover",
  "04": "icon--bgmenu-04",
  "05__active": "icon--bgmenu-05-active",
  "05__hover": "icon--bgmenu-05-hover",
  "05": "icon--bgmenu-05",
  "06__active": "icon--bgmenu-06-active",
  "06__hover": "icon--bgmenu-06-hover",
  "06": "icon--bgmenu-06",
  "07__active": "icon--bgmenu-07-active",
  "07__hover": "icon--bgmenu-07-hover",
  "07": "icon--bgmenu-07",
  "08__active": "icon--bgmenu-08-active",
  "08__hover": "icon--bgmenu-08-hover",
  "08": "icon--bgmenu-08",
  "09__active": "icon--bgmenu-09-active",
  "09__hover": "icon--bgmenu-09-hover",
  "09": "icon--bgmenu-09",
  "10__active": "icon--bgmenu-10-active",
  "10__hover": "icon--bgmenu-10-hover",
  "10": "icon--bgmenu-10",
  "11__active": "icon--bgmenu-11-active",
  "11__hover": "icon--bgmenu-11-hover",
  "11": "icon--bgmenu-11",
  "12__active": "icon--bgmenu-12-active",
  "12__hover": "icon--bgmenu-12-hover",
  "12": "icon--bgmenu-12",
  "13__hover": "icon--bgmenu-13-hover",
  "13": "icon--bgmenu-13",
  "android__hover": "icon--bgmenu-android-hover",
  "android": "icon--bgmenu-android",
  "download": "icon--bgmenu-download",
  "downloadCenter": "icon--bgmenu-download-center",
  "ios__hover": "icon--bgmenu-ios-hover",
  "ios": "icon--bgmenu-ios",
  "menu-item__arrow": "icon--bgmenu-menu-item-arrow",
  "business_0": "icon--biz-business-0",
  "business_1": "icon--biz-business-1",
  "business_2": "icon--biz-business-2",
  "business_3": "icon--biz-business-3",
  "business_4": "icon--biz-business-4",
  "business_5": "icon--biz-business-5",
  "business_6": "icon--biz-business-6",
  "business_7": "icon--biz-business-7",
  "business_8": "icon--biz-business-8",
  "chat_cms": "icon--broadside-cms-chat-cms",
  "chat_hovered_cms": "icon--broadside-cms-chat-hovered-cms",
  "qq_cms": "icon--broadside-cms-qq-cms",
  "qq_hovered_cms": "icon--broadside-cms-qq-hovered-cms",
  "qrcode_cms": "icon--broadside-cms-qrcode-cms",
  "qrcode_hovered_cms": "icon--broadside-cms-qrcode-hovered-cms",
  "tel_cms": "icon--broadside-cms-tel-cms",
  "tel_hovered_cms": "icon--broadside-cms-tel-hovered-cms",
  "chat_hovered": "icon--broadside-home-chat-hovered",
  "chat": "icon--broadside-home-chat",
  "feedback_hovered": "icon--broadside-home-feedback-hovered",
  "feedback": "icon--broadside-home-feedback",
  "qq_hovered": "icon--broadside-home-qq-hovered",
  "qq": "icon--broadside-home-qq",
  "qrcode_hovered": "icon--broadside-home-qrcode-hovered",
  "qrcode": "icon--broadside-home-qrcode",
  "tel_hovered": "icon--broadside-home-tel-hovered",
  "tel": "icon--broadside-home-tel",
  "sideTop_hover": "icon--broadside-side-top-hover",
  "sideTop": "icon--broadside-side-top",
  "close_chat_hover": "icon--chat-close-chat-hover",
  "close_chat": "icon--chat-close-chat",
  "feige_grey": "icon--chat-feige-grey",
  "feige_red": "icon--chat-feige-red",
  "feige_white": "icon--chat-feige-white",
  "search": "icon--chat-search",
  "close_gray": "icon--close-gray",
  "close": "icon--close",
  "closeBg": "icon--close-bg",
  "company-verify-icon": "icon--company-verify-icon",
  "default_company_logo": "icon--default-company-logo",
  "defaultavatar": "icon--defaultavatar",
  "dot": "icon--dot",
  "error": "icon--error",
  "fht-alert-warning": "icon--fht-alert-warning",
  "huoliao_disabled": "icon--huoliao-disabled",
  "huoliao_enabled": "icon--huoliao-enabled",
  "remodal-close__hover": "icon--remodal-close-hover",
  "remodal-close": "icon--remodal-close",
  "right_go": "icon--right-go",
  "right": "icon--right",
  "select-emoji": "icon--select-emoji",
  "upload-image": "icon--upload-image",
  "verified_sm": "icon--verified-sm",
  "verified": "icon--verified",
  "video_play": "icon--video-play",
  "vip-icon": "icon--vip-icon",
  "warning": "icon--warning",
  "collect_active": "icon--collect-collect-active",
  "collect_grey": "icon--collect-collect-grey",
  "collect_hover": "icon--collect-collect-hover",
  "collect_red_hollow": "icon--collect-collect-red-hollow",
  "collect_red_hover": "icon--collect-collect-red-hover",
  "collect": "icon--collect-collect",
  "company-card-modify": "icon--company-company-card-modify",
  "companyAuthentication_in": "icon--company-company-authentication-in",
  "companyAuthentication_notpass": "icon--company-company-authentication-notpass",
  "companyAuthentication_pass": "icon--company-company-authentication-pass",
  "lovely": "icon--emoji-lovely",
  "red_packet": "icon--emoji-red-packet",
  "refresh_error_hover": "icon--errors-refresh-error-hover",
  "refresh_error": "icon--errors-refresh-error",
  "cert1": "icon--footer-cert-1",
  "cert2": "icon--footer-cert-2",
  "cert3": "icon--footer-cert-3",
  "forward_red": "icon--forward-forward-red",
  "forward_white": "icon--forward-forward-white",
  "active-company": "icon--home-active-company",
  "appreg": "icon--home-appreg",
  "banner-arrow-left": "icon--home-banner-arrow-left",
  "banner-arrow-right": "icon--home-banner-arrow-right",
  "guid": "icon--home-guid",
  "header-qrcode-icon": "icon--home-header-qrcode-icon",
  "hot-product-icon": "icon--home-hot-product-icon",
  "hot-publish": "icon--home-hot-publish",
  "mobile_pop_ads_close_hover_active": "icon--home-mobile-pop-ads-close-hover-active",
  "mobile_pop_ads_close": "icon--home-mobile-pop-ads-close",
  "newest-publish": "icon--home-newest-publish",
  "pcreg": "icon--home-pcreg",
  "refresh__home_active__hover": "icon--home-refresh-home-active-hover",
  "refresh_home": "icon--home-refresh-home",
  "teamwork": "icon--home-teamwork",
  "businessFriend": "icon--home-official-intro-business-friend",
  "communication": "icon--home-official-intro-communication",
  "myCompany": "icon--home-official-intro-my-company",
  "recommendBusiness": "icon--home-official-intro-recommend-business",
  "left-default-arrow": "icon--paging-left-default-arrow",
  "left-disable-arrow": "icon--paging-left-disable-arrow",
  "left-hover-arrow": "icon--paging-left-hover-arrow",
  "right-default-arrow": "icon--paging-right-default-arrow",
  "right-disable-arrow": "icon--paging-right-disable-arrow",
  "right-hover-arrow": "icon--paging-right-hover-arrow",
  "phone": "icon--product-detail-phone",
  "report_grey": "icon--report-report-grey",
  "report_red": "icon--report-report-red",
  "advanced-search": "icon--whole-advanced-search",
  "back-header-logo": "icon--whole-back-header-logo",
  "company-verified-icon": "icon--whole-company-verified-icon",
  "down-arrow-red": "icon--whole-down-arrow-red",
  "down-arrow-white": "icon--whole-down-arrow-white",
  "down-arrow": "icon--whole-down-arrow",
  "downArrow_triangle": "icon--whole-down-arrow-triangle",
  "edit__hover": "icon--whole-edit-hover",
  "edit": "icon--whole-edit",
  "fht-notice-close__hover": "icon--whole-fht-notice-close-hover",
  "fht-notice-close": "icon--whole-fht-notice-close",
  "forward__hover": "icon--whole-forward-hover",
  "forward": "icon--whole-forward",
  "index-logo": "icon--whole-index-logo",
  "left-small-arrow": "icon--whole-left-small-arrow",
  "location": "icon--whole-location",
  "member-apply-close__hover": "icon--whole-member-apply-close-hover",
  "member-apply-close": "icon--whole-member-apply-close",
  "normal-user-icon": "icon--whole-normal-user-icon",
  "noticeIcon": "icon--whole-notice-icon",
  "report__active__hover": "icon--whole-report-active-hover",
  "report": "icon--whole-report",
  "right-small-arrow__hover": "icon--whole-right-small-arrow-hover",
  "right-small-arrow": "icon--whole-right-small-arrow",
  "smaller-logo": "icon--whole-smaller-logo",
  "successIcon": "icon--whole-success-icon",
  "vip-bronze-icon": "icon--whole-vip-bronze-icon",
  "vip-gold-icon": "icon--whole-vip-gold-icon",
  "vip-silver-icon": "icon--whole-vip-silver-icon"
};