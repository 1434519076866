import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/web.timers.js";
import { assert } from "@/common/assert";
import "./styles/home.scss";
import "@/pages/base/shared";
import "@/pages/base/styles/base/UIStandard.front.scss";
import "@/pages/base/styles/base/UIStandard.recommendBox.scss";
import "@/pages/base/styles/layout/layout.scss";
import "@/pages/modules/searchBox";
import "@/pages/modules/topbar";
import "@/pages/modules/foregroundHeader";
import "@/pages/uistandards/qrcode";
import { createLiveQuery } from "@/pages/vendors/vendor.livequery";
import { createLazyLoad } from "@/pages/vendors/vendor.lazyload";
import { Slick, slickNext } from "@/pages/vendors/vendor.slick";
import jqueryDataUtil from "@/common/jqueryDataUtil";
import { Icon } from "@/assets/sprites/icon";
$(function () {
  import(
  /* webpackChunkName: "mobilePopAds" */
  "@/pages/modules/mobilePopAds");
  new Slick($(".home-sliders"), {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    fade: true,
    autoplay: true,
    cssEase: "linear",
    prevArrow: " <a href=\"javascript:void(0);\" class=\"icon " + Icon["banner-arrow-left"] + " home-sliders__arrow-left\"></a>",
    nextArrow: "<a href=\"javascript:void(0);\" class=\"icon " + Icon["banner-arrow-right"] + " home-sliders__arrow-right\"></a>"
  });
  $(".home-sliders").mouseenter(function () {
    $(".home-sliders__arrow-left,.home-sliders__arrow-right").css({
      opacity: 1
    });
  }).mouseleave(function () {
    $(".home-sliders__arrow-left,.home-sliders__arrow-right").css({
      opacity: 0
    });
  }); //首页左侧导航

  import("@/pages/vendors/vendor.hoverIntent").then(function (_a) {
    var createHoverIntent = _a.createHoverIntent;
    createHoverIntent($(".left-menunav li"), {
      timeout: 200,
      over: function over() {
        var $this = $(this);
        var title = $this.find(".left-menunav__titlelink").text().replace(/\s*/g, "");
        var $sidebar = $this.find(".left-menunav__sidebar");
        var $top = $(".left-menunav").offset().top + $(".left-menunav").height();
        var $maxTop = 182;
        var $height = $this.offset().top + $sidebar.outerHeight();
        var $sideBarTop = $height - $top;

        if ($sideBarTop >= 0) {
          $sideBarTop = $sideBarTop >= $maxTop ? $maxTop : $sideBarTop;
          $sidebar.css({
            top: $sideBarTop + "px"
          });
        }

        $sidebar.show(); // gtag("event", "hover_homeleftmenunav", {
        //     event_category: "hover",
        //     event_label: "leftmenunav",
        //     value: title,
        // });
      },
      out: function out() {
        var $this = $(this);
        var $sidebar = $this.find(".left-menunav__sidebar");
        $sidebar.hide();
      }
    });
  });
  createLazyLoad($("img.lazyload"), {
    threshold: 360
  });
});
$(function () {
  var points = [[{
    x: 107,
    y: 150
  }, {
    x: 180,
    y: 135
  }, {
    x: 180,
    y: 100
  }, {
    x: 107,
    y: 212
  }], [{
    x: 209,
    y: 80
  }, null], [{
    x: 125,
    y: 230
  }, {
    x: 209,
    y: 410
  }, null], [{
    x: 180,
    y: 78
  }, {
    x: 209,
    y: 78
  }], [{
    x: 180,
    y: 250
  }, {
    x: 195,
    y: 135
  }], [{
    x: 12,
    y: 83
  }, null, {
    x: 78,
    y: 83
  }, null], [{
    x: 109,
    y: 410
  }, {
    x: 20,
    y: 270
  }, {
    x: 130,
    y: 410
  }], [{
    x: 170,
    y: 113
  }, {
    x: 160,
    y: 105
  }, null]]; //触摸点闪烁

  function touchAni() {
    $(".home-banner1__touchbtn,.home-banner1__kk").animate({
      opacity: 0
    }, 500).animate({
      opacity: 1
    }, 500, function () {
      touchAni();
    });
  }

  touchAni(); //手机屏幕切换

  var mobiScreenMotion = {
    init: function init() {
      this.btns.eq(0).addClass("selected");
      $(".home-banner1__screen-item").eq(0).show();
      this.imgShow($(".home-banner1__screen-item").eq(0), 0);
      this.bind();
    },
    timer: undefined,
    timer2: undefined,
    timeSpace: 3000,
    btns: $(".home-banner1__icons li"),
    bind: function bind() {
      var that = this;
      this.btns.mouseover(function () {
        var idx = $(this).index();

        if ($(this).hasClass("selected")) {
          return false;
        } else {
          $(this).addClass("selected").siblings().removeClass("selected");
        }

        that.conShow($(".home-banner1__screen-item").eq(idx));
        that.imgShow($(".home-banner1__screen-item").eq(idx), idx);

        if ($(".fht_jslt img:first-child").is(":visible")) {
          $(".home-banner1__kk").show();
          setTimeout(function () {
            $(".home-banner1__kk").hide();
          }, 3000);
        }

        return;
      });
    },
    imgShow: function imgShow(obj, idx) {
      var that = this;
      var $obj = $(obj);
      var imageLength = $obj.children("img").length;
      $obj.find("img").eq(0).show();
      var i = 0;
      that.timer && clearInterval(that.timer);
      that.setTouch(idx, i);
      that.timer = setInterval(function () {
        if (i < imageLength - 1) {
          i++;
        } else {
          i = 0;
        }

        that.setTouch(idx, i);
        $obj.find("img").eq(i).fadeIn().siblings("img").fadeOut();
      }, that.timeSpace);
    },
    conShow: function conShow(obj) {
      $(obj).find("img").hide();
      $(obj).show();
      $(obj).siblings(".home-banner1__screen-item").hide();
    },
    setTouch: function setTouch(i, j) {
      var $touchBtn = $(".home-banner1__touch");
      $touchBtn.hide();
      this.timer2 && clearTimeout(this.timer2);

      if (points[i][j] !== null && points[i][j] !== undefined) {
        this.timer2 = setTimeout(function () {
          $touchBtn.show();
          $(".home-banner1__touchbtn,.home-banner1__kk").stop().css({
            opacity: 1
          });
          touchAni();
        }, 1500);
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */

        var x = points[i][j].x;
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */

        var y = points[i][j].y;
        $touchBtn.css({
          left: x,
          top: y
        });
      } else {
        $touchBtn.hide();
      }
    }
  }; //手机屏幕切换，初始化执行

  mobiScreenMotion.init();
}); //热销产品、新品发布

$(function () {
  $(".hot-products").each(function (index, element) {
    var $this = $(this);

    if ($this.find("li").length > 3) {
      new Slick($this, {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3
      });
      $this.siblings(".layout-home__item-title").find(".layout-home__item-title-link").fadeIn(300);
    }
  });
  $(".js-refresh").click(function () {
    var $this = $(this);
    slickNext($this.closest(".layout-home__item-title").siblings(".hot-products"));
  });
  $(".hot-products").fadeIn(300);
}); //发现好货、发现商机

$(function () {
  function initList($dom) {
    if ($dom.find("li").length > 5 && !$dom.is(".slick-initialized")) {
      new Slick($dom, {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true
      });
    }
  }

  $(".home-full-line__list").each(function (index, element) {
    var $this = $(this);
    initList($this);
  });
  setTimeout(function () {
    $(".home-full-line__list").fadeIn(300);
  }, 0);
  createLiveQuery($(".home-full-line__list"), function () {
    var $this = $(this);
    initList($this);

    if (!$this.is(":visible")) {
      $this.fadeIn(300);
    }
  });
});
/*固定URL的$.load封装*/

$(function () {
  var $el = $(".js-ajax-next-page");
  $el.each(function () {
    var $nextPageBtn = $(this); // const url = $nextPageBtn.data("ajax-url");

    var url = jqueryDataUtil.requiredString($nextPageBtn, "ajax-url");
    var limit = jqueryDataUtil.requiredNumber($nextPageBtn, "limit");
    assert(url, "ajax-url is falsy");
    assert(limit, "limit is falsy");
    var $parentTitle = $nextPageBtn.closest(".layout-home__item-title"); // assert($parentTitle.length, "only work when .ajax-next-page is under a .layout-home__item-title: " + $parentTitle.length);

    var ANIMATION_PERIOD = 300; //initial load
    // $.get(url, { Limit: limit }, function (html) {
    //     $parentTitle.after(html);
    // });

    $nextPageBtn.click(function () {
      var nextPageNumber = jqueryDataUtil.optionalNumber($nextPageBtn, "current-page") || 2;
      $nextPageBtn.css("cursor", "wait");
      $.get(url, {
        Limit: limit,
        currPage: nextPageNumber
      }, function (html, _textStatus, xhr) {
        if (xhr.status === 204) {
          $nextPageBtn.data("current-page", 1);
          /* eslint-disable-next-line fht-eslint-rules/restricted-jquery-methods */

          $nextPageBtn.click();
        } else {
          /* eslint-disable-next-line fht-eslint-rules/restricted-jquery-methods-error */
          $parentTitle.next().remove();
          $parentTitle.after(html).show(ANIMATION_PERIOD);
          $nextPageBtn.data("current-page", nextPageNumber + 1);
        }
      }).always(function () {
        return $nextPageBtn.css("cursor", "");
      });
    });
  });
}); //首页官网介绍

$(function () {
  var isHovering = false;
  var slick = new Slick($(".offical-intro__left"), {
    infinite: true,
    autoplay: true,
    cssEase: "linear",
    arrows: false
  });
  slick.onBeforeChange(function (slick, currentSlide, nextSlide) {
    if (isHovering) return;
    var index = nextSlide;

    if (index === 3) {
      index = 0;
    } else {
      index += 1;
    }

    $(".offical-intro__desc-item:visible").stop().fadeOut(0);
    $(".offical-intro__desc-item").eq(index).stop().fadeIn(0);
    $(".offical-intro__item").removeClass("offical-intro__item-active").eq(index).addClass("offical-intro__item-active");
  });
  $(".offical-intro__item").on("mouseenter", function () {
    var index = $(".offical-intro__item").index($(this));
    var $this = $(this);
    isHovering = true;
    if ($this.is(".offical-intro__item-active")) return;
    slick.slickPause();
    $this.siblings().removeClass("offical-intro__item-active");
    $this.addClass("offical-intro__item-active");
    $(".offical-intro__desc-item:visible").stop().fadeOut(0);
    $(".offical-intro__desc-item").eq(index).stop().fadeIn(0);
    slick.slickGoTo(index);
  }).on("mouseleave", function () {
    isHovering = false;
    slick.slickPlay();
  });
}); //首页悬浮二维码 埋点

$(function () {
  $(".fht-qrcode").mouseenter(function () {// mta.clickStat("hover_qrcode", { hometop: "true" });
  });
}); //最新动态

$(function () {
  $(document.body).on("click", ".newest-dynamic__list li", function () {
    var $this = $(this);
    var index = $(".newest-dynamic__list li").index($this);
    var $links = $(".newest-dynamic__list-link");
    if ($this.find(".newest-dynamic__list-link").is(".newest-dynamic__list-link-active")) return;
    $links.removeClass("newest-dynamic__list-link-active");
    $this.find(".newest-dynamic__list-link").addClass("newest-dynamic__list-link-active");
    $(".newest-item").eq(index).removeClass("newest-dynamic__item").addClass("newest-dynamic__item-active");
    $(".newest-item").not(":eq(" + index + ")").removeClass("newest-dynamic__item-active").addClass("newest-dynamic__item");
  });
  $(".newest-item").eq(0).removeClass("newest-dynamic__item").addClass("newest-dynamic__item-active");
}); //入驻企业

$(function () {
  if ($(".teamwork-company__right li").length > 2) {
    createLiveQuery($(".teamwork-company__right"), function () {
      var $this = $(this);
      new Slick($this, {
        slidesToShow: 2,
        slidesToScroll: 2,
        vertical: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        autoplay: true,
        cssEase: "linear"
      });
    });
  }
});
console.log("HOME Loaded");